import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

const Login = () => import('@/pages/application/login/Login')
const Logout = () => import('@/pages/application/login/Logout')

const Users = () => import('@/pages/application/Users')
const Roles = () => import('@/pages/application/Roles')

const AssetCategories = () => import('@/pages/application/AssetCategories')
const AssetLocations = () => import('@/pages/application/AssetLocations')
const AssetDepartaments = () => import('@/pages/application/AssetDepartaments')
const AssetConditions = () => import('@/pages/application/AssetConditions')
const AssetPersons = () => import('@/pages/application/AssetPersons')

//Reports
const AssetsGeneralReport = () => import('@/pages/application/AssetsGeneralReport')
const AssetsPersonsReport = () => import('@/pages/application/AssetsPersonsReport')

//Gestion patrimonial
const AssetsCatalog = () => import('@/pages/application/AssetsCatalog')
const InactiveAssetsCatalog = () => import('@/pages/application/InactiveAssetsCatalog')
// const AssetsPersonsReport = () => import('@/pages/application/AssetsPersonsReport')
const ShowAsset = () => import('@/pages/application/ShowAsset')

//Users
const MyAccount = () => import('@/pages/application/configuration/MyAccount')

// Views
const Home = () => import('@/pages/application/Home')
Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      meta: {
        is_public: true,
      },
      component: Logout
    },
    {
      path: '/qr/assets/:qr',
      name: 'Consulta de Activo',
      meta: {
        is_public: true,
      },
      component: ShowAsset
    },
    {
      path: '/',
      redirect: 'home',
      name: 'Home',
      component: TheContainer,
      children: [
        //CONFIGURACION
        {
          path: '/asset-categories',
          name: 'Categorías',
          component: AssetCategories
        },
        {
          path: '/asset-locations',
          name: 'Lugares',
          component: AssetLocations
        },
        {
          path: '/asset-departaments',
          name: 'Departamentos',
          component: AssetDepartaments
        },
        {
          path: '/asset-conditions',
          name: 'Condiciones (Estados)',
          component: AssetConditions
        },
        {
          path: '/asset-persons',
          name: 'Personas',
          component: AssetPersons
        },
        //REPORTES
        {
          path: '/reports/people',
          name: 'aaaa',
          component: AssetsPersonsReport
        },
        {
          path: '/reports/assets',
          name: 'bbbb',
          component: AssetsGeneralReport
        },
        //GESTION PATRIMONIAL
        {
          path: '/assets',
          name: 'Inventario de Activos',
          component: AssetsCatalog
        },
        {
          path: '/inactive-assets',
          name: 'Inventario de Activos (eliminados)',
          component: InactiveAssetsCatalog
        },
        {
          path: '/assets/:category',
          name: 'Inventario de Activos',
          component: AssetsCatalog
        },
        // {
        //   path: '/assets/persons',
        //   name: 'Personas',
        //   component: AssetPersons
        // },
        //CUENTA
        {
          path: '/users',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/roles',
          name: 'Roles',
          component: Roles
        },
        {
          path: '/my-account',
          name: 'Mi Cuenta',
          component: MyAccount
        },
      ]
    },    
    {
      path: '/',
      redirect: 'home',
      name: 'Gestión Patrimonial',
      component: TheContainer,
      children: [
        {
          path: '/home',
          name: 'Home Page',
          component: AssetsCatalog,
          meta: {
            is_public: false,
          },
        },
      ]
    },    
  ]
})